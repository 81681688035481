import { customActionHandler, dispatchCustomAction } from '@/features/customActions';
import { Box, Flex } from '@chakra-ui/react';
import type { ButtonConfig } from '@ldfeplatform/drx-component-library.shared-types.types';
import { Scroller } from '@ldfeplatform/drx-component-library.ui.atoms.scroller';
import { Card } from '@ldfeplatform/drx-component-library.ui.molecules.card-component';
import { useUnit } from 'effector-react';
import type { FunctionComponent } from 'react';
import type { FeatureCardFieldsFragment, Maybe } from '../contentful';

export type CardComponentProps = {
	cards?: Maybe<Maybe<FeatureCardFieldsFragment>[]>;
};

export const CardComponent: FunctionComponent<CardComponentProps> = ({ cards = [] }) => {
	const dispatchCustomActionEvent = useUnit(dispatchCustomAction);
	if (!cards) {
		return <></>;
	}

	return (
		<Box
			pt={{
				base: 10,
				lg: 20,
			}}
			pb={{
				base: 10,
				lg: 16,
			}}
			px={{
				base: 8,
				'2xl': 32,
			}}
		>
			<Scroller>
				<Flex gap={4}>
					{cards.map((card) => {
						const cardCTA = card?.ctaCollection?.items.map((item) => {
							let onClickHandler = () => {};
							if (item) {
								onClickHandler = () => {
									customActionHandler(
										{
											identifier: item.customAction,
											params: item.customActionParameters,
											redirectLink: item.linkUrl,
										},
										dispatchCustomActionEvent
									);
								};
							}
							return { ...item, onClickHandler };
						});
						return (
							card && (
								<Card
									key={`${card.heading}-${card.body}`}
									badgeText={card.badge || ''}
									buttonConfigs={cardCTA as ButtonConfig[]}
									cardBenefitText={card.body || ''}
									imageSrc={card.image?.asset?.url || ''}
									cardTitle={card.heading || ''}
									imageAltText={card.image?.altText || ''}
								/>
							)
						);
					})}
				</Flex>
			</Scroller>
		</Box>
	);
};
