import type { SessionContextParams } from '@/shared/session';
import { createEvent, createStore, sample } from 'effector';
import { useUnit } from 'effector-react';
import { useEffect, type FunctionComponent } from 'react';

const $applicationLoaded = createStore(false);
const applicationLoaded = createEvent();

export const applicationDidLoad = createEvent();

// This store is hydrated from the Provider in _app.tsx
export const $session = createStore<SessionContextParams>({ status: 'unauthenticated' }, { sid: '$session' });

sample({
	clock: applicationLoaded,
	source: $applicationLoaded,
	filter: (source) => !source,
	fn: () => true,
	target: [$applicationLoaded, applicationDidLoad],
});

export const ApplicationLoader: FunctionComponent = () => {
	const handler = useUnit(applicationLoaded);

	useEffect(() => {
		console.info('Application Loaded');
		handler();
	}, [handler]);

	return <></>;
};
