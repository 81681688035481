import { AccountManager } from '@/entities/account';
import { DefaultLocale } from '@/shared/config';
import { Image, Text } from '@chakra-ui/react';
import type { CTAVariant } from '@ldfeplatform/drx-component-library.shared-types.types';
import type { ModalProps } from '@ldfeplatform/drx-component-library.ui.molecules.modal';
import { Modal } from '@ldfeplatform/drx-component-library.ui.molecules.modal';
import { useUnit } from 'effector-react';
import { useRouter } from 'next/router';
import { type FunctionComponent } from 'react';
import { useModalContentQuery } from '../../contentful';
import { updateGTMDataLayer } from '../../hooks';
import { replaceStringTemplates, type ModalIdentifer } from '../../template';

export type ModalContentProps = {
	isOpen: boolean;
	onClose: () => void;
	identifier: ModalIdentifer;
	storeData?: object | null;
	ctaOrientation?: ModalProps['ctaOrientation'];
	onProceed?: () => void;
	isMarkdownModal?: boolean;
};

export const ModalContent: FunctionComponent<ModalContentProps> = ({
	isOpen,
	onClose,
	identifier,
	storeData,
	ctaOrientation,
	isMarkdownModal,
	onProceed,
}) => {
	const router = useRouter();
	const account = useUnit(AccountManager.$account);
	const { data, loading, error } = useModalContentQuery({
		variables: { locale: router.locale ?? DefaultLocale, identifier },
	});

	const modalContent = data?.modalCollection?.items[0]?.featureCard;

	if (loading || error || !modalContent) {
		if (error) {
			console.error(error);
		}
		return null;
	}

	const { heading, body, image, ctaCollection } = modalContent;

	const modalCTAs = ctaCollection?.items?.map((cta) => {
		let onClick: () => void;

		if (cta?.linkUrl === '#proceed' && typeof onProceed === 'function') {
			onClick = onProceed;
		} else if (cta?.linkUrl !== null) {
			onClick = () => router.push(cta?.linkUrl as string);
		} else {
			onClick = onClose;
		}

		return {
			label: cta?.label ?? '',
			variant: (cta?.variant ?? 'tertiary') as CTAVariant,
			onClick: () => {
				if (cta?.dataAnalytics) updateGTMDataLayer({ event: 'customLink', linkName: cta?.dataAnalytics });
				onClick();
			},
		};
	});

	const modelText = storeData && Object.keys(storeData).length === 0 ? body : replaceStringTemplates(body, storeData!);

	return (
		<Modal
			isOpen={isOpen}
			title={replaceStringTemplates(heading ?? '', account?.profile ?? {})}
			onClose={onClose}
			autoFocus={false}
			footerCTAs={modalCTAs}
			isCentered
			ctaOrientation={ctaOrientation}
			{...(isMarkdownModal ? { markdown: modelText } : null)}
		>
			{!isMarkdownModal && (
				<Text textAlign="center" whiteSpace="pre-line">
					{modelText}
				</Text>
			)}
			{/* Ideally the width and height would come from contentful to help with CLS performance metrics */}
			{image && <Image mx="auto" htmlWidth={338} htmlHeight={217} src={image?.asset?.url} alt={image?.altText ?? ''} />}
		</Modal>
	);
};
