import { AccountManager } from '@/entities/account';
import { convertCTA, convertCTAWithParameters, convertDamAsset } from '@/shared/contentfulTypeConverters';
import { useEnvironment } from '@/shared/environment';
import { browserLogger as logger } from '@/shared/instrumentation/browserLogger';
import { Box } from '@chakra-ui/react';
import type { CallToAction } from '@ldfeplatform/drx-component-library.shared-types.types';
import {
	Banner as BannerImpl,
	type BannerProps as BannerComponentProps,
} from '@ldfeplatform/drx-component-library.ui.molecules.banner';
import type { BannerPosition } from '@ldfeplatform/drx-component-library.ui.templates.two-column-banner';
import { useUnit } from 'effector-react';
import { useRouter, type NextRouter } from 'next/router';
import { encode } from 'querystring';
import { type Dispatch, type FunctionComponent, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
	dmrPchSlug,
	dmrSdmSlug,
	fastpassSlug,
	vaxcareSlug,
	type BannerFieldsFragment,
	type Maybe,
} from '../contentful';
import { useToast } from '../hooks/use-toast';
import { validateAvailabilityForBooking, vaxcareConfirmAppointment } from '../services/customer-api';
import { extractUtmParameters, getUtmSourceBySlug, replaceStringTemplates } from '../template/templateHelpers';
import { normalizeRoute } from '../utilities/routing';

type ToastInfo = {
	title: string;
	description: string;
};

export type BannerType = 'hero' | 'feature';

export type BannerProps = {
	content: Maybe<BannerFieldsFragment>;
	loading: boolean;
	type: BannerType;
	reverseOrder?: boolean;
	setStore?: Dispatch<SetStateAction<object>>;
};

export type ActionClickProps = {
	baseUrl?: string;
	modifiedQuery: Record<string, string | string[] | undefined>;
	router: NextRouter;
	setStore?: Dispatch<SetStateAction<object>>;
	displayToast?: (isSuccess: boolean, message: string, description?: string) => void;
	errorToastInfo?: ToastInfo;
};

export type OnAlternativeProps = ActionClickProps & {
	urlParameters: string;
	content: Maybe<BannerFieldsFragment>;
};

export type OnActionClickProps = ActionClickProps & {
	decodedData: string;
	redirectAction: CallToAction;
};

export const OnAlternativeClick = async ({
	baseUrl = '',
	modifiedQuery,
	router,
	content,
	urlParameters,
	setStore = undefined,
	displayToast,
	errorToastInfo,
}: OnAlternativeProps) => {
	const key = typeof modifiedQuery.key === 'string' ? encodeURIComponent(modifiedQuery.key) : undefined;
	if (!key || baseUrl === '') {
		logger.info('Key or BaseURL might be empty or undefined.');
		return undefined;
	}
	const response = await validateAvailabilityForBooking(baseUrl, key);
	if (response) {
		switch (response.status) {
			case 200:
				await router.push(content?.alternateLink?.linkUrl + urlParameters);
				break;
			case 409:
				if (setStore) {
					const { storeData } = await response.json();
					setStore(storeData);
				}
				await router.push(`${router.asPath}&pickupNotAvailable=true`);
				break;
			default:
				if (displayToast && errorToastInfo) {
					displayToast(false, errorToastInfo.title, errorToastInfo.description);
				}
				break;
		}
	}
};

export const OnActionClickDMR = ({ baseUrl = '', modifiedQuery, router }: ActionClickProps) => {
	if (!baseUrl || !modifiedQuery?.storeId) {
		logger.info('dmr landing - BaseURL or storeId might be empty or undefined');
		return undefined;
	}
	const storeId = modifiedQuery?.storeId;
	const slug = normalizeRoute(modifiedQuery.slug);

	const source = getUtmSourceBySlug(slug);
	let updatedUrl = baseUrl.replace('{storeId}', storeId.toString());
	const utmParameters = extractUtmParameters(router.query);

	const updatedUtmValues = updateDmrUtmValues(
		utmParameters?.utm_campaign,
		utmParameters?.utm_medium,
		utmParameters?.utm_source,
		source
	);

	const ctaUtmParameters: string =
		updatedUtmValues.updatedCampaign + updatedUtmValues.updatedMedium + updatedUtmValues.updatedSource;
	updatedUrl = updatedUrl + '?' + ctaUtmParameters;
	router.push(updatedUrl);
};

export const OnActionClick = async ({
	baseUrl = '',
	modifiedQuery,
	decodedData,
	router,
	redirectAction,
}: OnActionClickProps) => {
	const key = typeof modifiedQuery.key === 'string' ? encodeURIComponent(modifiedQuery.key) : undefined;
	const parseStoreId = JSON.parse(decodedData).storeId;
	if (!key || !parseStoreId || baseUrl === '') {
		logger.info('Key, parseStoreId or BaseURL might be empty or undefined.');
		return undefined;
	}
	const utmParameters = extractUtmParameters(router.query);
	const utm_content = utmParameters?.utm_content ? '&utm_content=' + utmParameters?.utm_content : '';
	const response = await validateAvailabilityForBooking(baseUrl, key);
	switch (response?.status) {
		case 200:
			await vaxcareConfirmAppointment(baseUrl, key, decodedData, router.locale || 'en');
			await router.push(
				redirectAction.linkUrl +
					'/' +
					parseStoreId.padStart(4, '0') +
					'/schedule/activity/injection-shingles' +
					'?utm_campaign=vaxcare_shingrix_book_appointment~sdm' +
					'&utm_medium=web' +
					'&utm_source=digital_pharmacy' +
					utm_content
			);
			break;
		case 409:
		case 403:
			await router.push(
				redirectAction.linkUrl +
					'/' +
					parseStoreId.padStart(4, '0') +
					'/schedule/activity/injection-shingles' +
					'?utm_campaign=vaxcare_shingrix_book_appointment~sdm' +
					'&utm_medium=web' +
					'&utm_source=digital_pharmacy'
			);
			break;
		default:
			await router.push(
				`https://www.shoppersdrugmart.ca/${router.locale}/health-and-wellness/pharmacy-services/adult-vaccinations`
			);
			break;
	}
};

export const Banner: FunctionComponent<BannerProps> = ({ content, loading, type, reverseOrder = true, setStore }) => {
	const { t } = useTranslation('myProfile');
	const account = useUnit(AccountManager.$account);
	const { displayToast } = useToast();

	const environment = useEnvironment();
	const router = useRouter();
	const encodedData = (router.query?.data as string) || '';
	const decodedData = encodedData === '' ? encodedData : atob(decodeURIComponent(encodedData));
	const modifiedQuery = { ...router.query };
	if (decodedData.includes(fastpassSlug)) {
		delete modifiedQuery.slug;
	}

	const slug = normalizeRoute(modifiedQuery.slug);
	const defaultErrorTitle = t('toast.vaxcareDefaultErrorToast.title');
	const defaultErrorDescription = t('toast.vaxcareDefaultErrorToast.description');
	const errorToastInfo: ToastInfo = {
		title: defaultErrorTitle,
		description: defaultErrorDescription,
	};

	const queryString = new URLSearchParams(encode(modifiedQuery)).toString();
	const urlParameters = queryString === '' ? '' : '?' + queryString;

	if (!content) {
		return <></>;
	}

	const bannerProps: BannerComponentProps = {
		isLoading: loading,
		bannerType: type,
		heading: replaceStringTemplates(content?.heading || '', account?.profile ?? {}),
		description: content.descriptionV2 || '',
		imageAspectRatio1by1: convertDamAsset(content.imageAspectRatio1By1),
		imageAspectRatio16by9: convertDamAsset(content.imageAspectRatio1By1),
		imagePosition: (content.imagePosition as BannerPosition) || undefined,
		actionsRow: renderActionRow(content.actionRowsCollection, urlParameters, decodedData),
		reverseOrder,
		alternateText: content.alternateText || '',
		alternateLink:
			decodedData.includes(fastpassSlug) || urlParameters.includes(vaxcareSlug)
				? convertCTAWithParameters(content.alternateLink, urlParameters)
				: convertCTA(content.alternateLink),
		onBtnClick: () => {
			if (slug === vaxcareSlug) {
				return OnActionClick({
					baseUrl: environment.CustomerAPIEndpointUrl,
					modifiedQuery,
					decodedData,
					router,
					redirectAction: content.actionRowsCollection?.items[0]?.actionRowCollection?.items[0] as CallToAction,
					setStore,
				});
			} else if (slug === dmrPchSlug || slug === dmrSdmSlug) {
				return OnActionClickDMR({
					modifiedQuery,
					router,
				});
			} else {
				return undefined;
			}
		},
		reference: content.reference || undefined,
		onAlternateCtaClick:
			slug === vaxcareSlug
				? () =>
						OnAlternativeClick({
							baseUrl: environment.CustomerAPIEndpointUrl,
							modifiedQuery,
							router,
							content,
							urlParameters,
							setStore,
							displayToast,
							errorToastInfo,
						})
				: undefined,
	};

	return (
		<Box bg={content.backgroundColor?.value || 'current'}>
			<BannerImpl {...bannerProps} />
		</Box>
	);
};

export function renderActionRow(
	actionRowsCollection: BannerFieldsFragment['actionRowsCollection'],
	urlParameters?: string,
	decodedData?: string
) {
	return actionRowsCollection?.items?.reduce<Record<string, CallToAction[]>>((acc, row, idx) => {
		if (!row) {
			return acc;
		}
		const transformedItems = (row.actionRowCollection?.items || [])
			.filter((item) => item !== null)
			.map((item) => transformActionItem(item as CallToAction, urlParameters, decodedData));
		acc[`row-${idx}`] = transformedItems;

		return acc;
	}, {});
}

export function transformActionItem(item: CallToAction, urlParameters?: string, decodedData?: string): CallToAction {
	if (decodedData?.includes(fastpassSlug)) {
		return {
			...item,
			linkUrl: item.linkUrl ? item.linkUrl + urlParameters + '&fastpass_option=fastpass_login' : item.linkUrl || '',
		};
	}
	if (urlParameters?.includes(vaxcareSlug)) {
		return {
			...item,
			linkUrl: null,
		};
	}
	if (urlParameters?.includes(`slug=${dmrPchSlug}`) && urlParameters?.includes('storeId')) {
		const updatedLinkUrl = item?.linkUrl ? updateDmrLinkUrl(urlParameters, item?.linkUrl) : null;
		return {
			...item,
			linkUrl: updatedLinkUrl,
		};
	}
	return item;
}

export function updateDmrLinkUrl(urlParameters: string, linkUrl: string | null): string | null {
	if (!linkUrl) {
		return null;
	}
	const searchParams = new URLSearchParams(urlParameters);
	let updatedLinkUrl = null;

	if (searchParams.has('storeId') && linkUrl) {
		const storeId = searchParams.get('storeId') as string;
		updatedLinkUrl = linkUrl.replace('{storeId}', storeId.toString());
	}
	const slug = searchParams.get('slug') || undefined;
	const source = getUtmSourceBySlug(slug);

	const updatedUtmValues = updateDmrUtmValues(
		searchParams.get('utm_campaign'),
		searchParams.get('utm_medium'),
		searchParams.get('utm_source'),
		source
	);

	updatedLinkUrl +=
		'?' + updatedUtmValues.updatedCampaign + updatedUtmValues.updatedMedium + updatedUtmValues.updatedSource;
	return updatedLinkUrl;
}

function updateDmrUtmValues(
	utmCampaign: string | null,
	utmMedium: string | null,
	utmSource: string | null,
	source: string
) {
	const updatedCampaign = utmCampaign ? 'utm_campaign=' + utmCampaign : 'utm_campaign=med_review_product';
	const updatedMedium = utmMedium ? '&utm_medium=' + utmMedium : '&utm_medium=web';
	const updatedSource = utmSource ? '&utm_source=' + utmSource : `&utm_source=${source}`;

	return {
		updatedCampaign,
		updatedMedium,
		updatedSource,
	};
}
