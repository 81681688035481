import { $locale, $route, changeLocationFx, pushPayload } from '@/entities/routerBridge';
import { $session, applicationDidLoad } from '@/features/application';
import { ConsentManager } from '@/features/consent/model';
import { CustomActionFactory } from '@/features/customActions/customAction';
import { errorToToast, getErrorToast, showToast } from '@/features/toast';
import { $environment } from '@/shared/environment';
import { testingInternals } from '@/shared/testingInternals';
import { createFactory, invoke } from '@withease/factories';
import { attach, createEffect, createEvent, createStore, sample } from 'effector';
import {
	UpdateUserProfileChannel,
	type PCHUserResponse,
	type UpdateUserProfile,
	type UserProfile,
} from '../../services/midtier/user/__generated';
import {
	AccountCreationSlug,
	AuthCallbackSlug,
	ClaimErrorSlug,
	SigninSlug,
	VerificationFailedSlug,
	VerificationNameDobSlug,
	VerificationPhoneSlug,
	claimBookingSlug,
} from '../../types/routes';
import {
	deleteUser,
	getAuth,
	getUser,
	handleSignIn as handleSignInFn,
	logout as logoutFn,
	patchUser,
	userFound,
	userNotFound,
	type GetAuthFxParams,
	type HandleSignInFxParams,
	type LogoutFxParams,
	type RedirectUrl,
} from './api';
import {
	LoginCustomActionPayloadContract,
	LogoutCustomActionPayloadContract,
	SignupCustomActionPayloadContract,
} from './contracts';

export type PcidSsoIntent = 'login' | 'register';

export type Account = Omit<PCHUserResponse, 'profile'> & { profile: UserProfile & { displayName: string } };

export type AccountStatus = 'fetching' | 'fetched' | 'no-profile' | 'error';

function shouldBypassRedirect(route: string) {
	const bypassRoutes = [
		'/createProfile/',
		claimBookingSlug,
		AccountCreationSlug,
		ClaimErrorSlug,
		VerificationFailedSlug,
		VerificationNameDobSlug,
		VerificationPhoneSlug,
		AuthCallbackSlug,
		SigninSlug,
	];

	return bypassRoutes.some((prefix) => route.startsWith(prefix));
}

export type AccountManagerFactoryParams = {
	applicationDidLoad: typeof applicationDidLoad;
	consentCheckCompleted: typeof ConsentManager.consentCheckCompleted;
	environment: typeof $environment;
	locale: typeof $locale;
	session: typeof $session;
	updateConsent: typeof ConsentManager.updateConsent;
};

const AccountManagerFactory = createFactory(
	({
		applicationDidLoad,
		consentCheckCompleted,
		environment,
		locale,
		session,
		updateConsent,
	}: AccountManagerFactoryParams) => {
		/* Stores */
		const $account = createStore<Account | undefined>(undefined, { skipVoid: false });
		const $accountStatus = createStore<AccountStatus>('no-profile');

		/* Effects */
		const getUserFx = createEffect(getUser);
		const patchUserFx = createEffect(patchUser);
		const deleteUserFx = createEffect(deleteUser);

		const baseGetAuthFx = createEffect(getAuth);
		const baseLogoutFx = createEffect(logoutFn);

		const getAuthFx = attach({
			source: [locale, environment] as const,
			mapParams: ({ customProps }: Pick<GetAuthFxParams, 'customProps'>, [locale, environment]) => {
				return {
					locale,
					baseUrl: environment.NextAuthUrl!,
					clientId: environment.ClientId!,
					clientSecret: environment.ClientSecret!,
					deploymentEnvironment: environment.DeploymentEnvironment!,
					customProps,
				};
			},
			effect: baseGetAuthFx,
		});

		const logoutFx = attach({
			source: [environment, session, locale] as const,
			mapParams: (
				{ explicit, redirectUrl }: Pick<LogoutFxParams, 'explicit' | 'redirectUrl'>,
				[environment, session, locale]
			) =>
				({
					baseUrl: environment.NextAuthUrl!,
					clientId: environment.ClientId!,
					clientSecret: environment.ClientSecret!,
					deploymentEnvironment: environment.DeploymentEnvironment!,
					explicit,
					idToken: session.status === 'authenticated' ? session.id_token : undefined,
					locale,
					redirectUrl,
				}) satisfies LogoutFxParams,
			effect: baseLogoutFx,
		});

		const loginFx = createEffect(async ({ returnUrl }: { returnUrl?: string }) => {
			const auth = await getAuthFx(returnUrl ? { customProps: { returnUrl } } : {});

			return auth.pcidLoginFunction();
		});

		const signupFx = createEffect(async ({ returnUrl }: { returnUrl?: string }) => {
			const auth = await getAuthFx(returnUrl ? { customProps: { returnUrl } } : {});

			return auth.pcidCreateAccountFunction();
		});

		const handleSignInFx = createEffect<Omit<HandleSignInFxParams, 'auth'>, RedirectUrl>(async ({ code, state }) => {
			const auth = await getAuthFx({});

			return handleSignInFn({ code, state, auth });
		});

		/* Events */
		const setAccount = createEvent<Account>();
		const login = createEvent<{ returnUrl?: string }>();
		const signup = createEvent<{ returnUrl?: string }>();
		const logout = createEvent<{ explicit: boolean; returnUrl?: string }>();
		const handleSignIn = createEvent<{ code: string; state: string }>();

		// * When application is fully loaded - trigger the loading of the profile, if the user is authenticated
		sample({
			clock: applicationDidLoad,
			source: session,
			filter: (session) => session.status === 'authenticated',
			target: getUserFx,
		});

		/* Get User Fx */
		sample({
			clock: getUserFx.fail,
			fn: () => getErrorToast('Error fetching account, please try again later'),
			target: showToast,
		});

		sample({
			clock: getUserFx.fail,
			fn(error) {
				if (error instanceof Error && error.message === 'Account not found') {
					return 'no-profile';
				}
				return 'error';
			},
			target: $accountStatus,
		});

		sample({
			clock: getUserFx.pending,
			fn: (pending) => (pending ? 'fetching' : 'fetched'),
			target: $accountStatus,
		});

		sample({
			clock: setAccount,
			fn: () => 'fetched' as const,
			target: $accountStatus,
		});

		sample({
			clock: getUserFx.doneData,
			filter: userFound,
			fn: (account) => account.profile.partnerConsents ?? [],
			target: updateConsent,
		});

		sample({
			clock: getUserFx.doneData,
			source: $route,
			filter: (route, response) => !shouldBypassRedirect(route) && userNotFound(response),
			fn: () => pushPayload('/createProfile/'),
			target: changeLocationFx,
		});

		/* Delete User Fx */
		sample({
			clock: deleteUserFx.fail,
			fn: () => getErrorToast('Error deleting account, please try again later'),
			target: showToast,
		});

		sample({
			clock: deleteUserFx.done,
			fn: () => ({ explicit: true, returnUrl: '/deletePending/' }),
			target: logout,
		});

		sample({
			clock: [getUserFx.doneData, setAccount],
			filter: userFound,
			fn: getAccountFromPCHUserResponse,
			target: $account,
		});

		// * When a consent check completes, see if this is a new consent, or the customer has toggled
		// * an existing consent, and if so - call the effect to do the update in midtier
		sample({
			clock: consentCheckCompleted,
			source: $account,
			filter: function isNewOrUpdatedConsent(account, consent) {
				const existingConsent = account?.profile.partnerConsents?.find((x) => x.partnerId === consent.partnerId);
				if (!existingConsent) {
					return true;
				}
				return existingConsent.tos !== consent.tos;
			},
			fn: (account, consent) => {
				return {
					...account?.profile,
					channel: UpdateUserProfileChannel.WEBAPP,
					partnerConsents: [consent],
				} satisfies UpdateUserProfile;
			},
			target: patchUserFx,
		});

		sample({
			clock: login,
			target: loginFx,
		});

		sample({
			clock: signup,
			target: signupFx,
		});

		sample({
			clock: [loginFx.doneData, signupFx.doneData, logoutFx.doneData, handleSignInFx.doneData],
			fn: pushPayload,
			target: changeLocationFx,
		});

		sample({
			clock: logout,
			target: logoutFx,
		});

		sample({
			clock: handleSignIn,
			target: handleSignInFx,
		});

		sample({
			clock: handleSignInFx.failData,
			fn: errorToToast,
			target: showToast,
		});

		sample({
			clock: handleSignInFx.fail,
			fn: () => pushPayload('/'),
			target: changeLocationFx,
		});

		return {
			$account,
			$accountStatus,
			setAccount,
			deleteAccount: deleteUserFx,
			login,
			signup,
			logout,
			handleSignIn,
			[testingInternals]: {
				baseGetAuthFx,
				baseLogoutFx,
				getAuthFx,
				handleSignInFx,
				loginFx,
				logoutFx,
				patchUserFx,
				signupFx,
			},
		};
	}
);

export const accountDataAvailable = (account: Account | undefined): account is Account => {
	return !!account;
};

export const getAccountFromPCHUserResponse = (response: PCHUserResponse): Account => {
	return {
		...response,
		profile: {
			...response.profile,
			displayName: response.profile.preferredFirstName || response.profile.firstName || '',
		},
	} satisfies Account;
};

export const AccountManager = invoke(AccountManagerFactory, {
	applicationDidLoad,
	session: $session,
	locale: $locale,
	environment: $environment,
	consentCheckCompleted: ConsentManager.consentCheckCompleted,
	updateConsent: ConsentManager.updateConsent,
});

export const loginCustomAction = invoke(() =>
	CustomActionFactory({ targetEvent: AccountManager.login, contract: LoginCustomActionPayloadContract, showToast })
);

export const logoutCustomAction = invoke(() =>
	CustomActionFactory({ targetEvent: AccountManager.logout, contract: LogoutCustomActionPayloadContract, showToast })
);

export const signupCustomAction = invoke(() =>
	CustomActionFactory({ targetEvent: AccountManager.signup, contract: SignupCustomActionPayloadContract, showToast })
);

export const __testing_internals__ = {
	AccountManagerFactory,
};
