import { createEvent } from 'effector';
import { useUnit } from 'effector-react';
import { useEffect, type FunctionComponent } from 'react';
import { useToast } from '../../hooks/use-toast';

export type ToastOptions = {
	isSuccess: boolean;
	message: string;
	description?: string;
};

export const showToast = createEvent<ToastOptions>();

export const ToastOutlet: FunctionComponent = () => {
	const toast = useToast();
	const showToastEvent = useUnit(showToast);

	useEffect(() => {
		const subscription = showToast.watch(({ isSuccess, message, description }) => {
			toast.displayToast(isSuccess, message, description);
		});

		window.showToast = showToastEvent;

		return subscription.unsubscribe;
	});

	return <></>;
};

export function errorToToast(e: Error): ToastOptions {
	return {
		isSuccess: false,
		message: e.message,
	};
}

export function getErrorToast(message: string, description?: string) {
	return { isSuccess: false, message, description };
}

export function getSuccessToast(message: string, description?: string) {
	return { isSuccess: true, message, description };
}
