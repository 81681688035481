import { Environment } from '@/shared/config';
import { createStore } from 'effector';
import { useUnit } from 'effector-react';

export type EnvironmentContextParams = Partial<{
	ClientId: string;
	ClientSecret: string;
	CustomerAPIEndpointUrl: string;
	DeploymentEnvironment: Environment;
	MidtierBookingManagementBaseUrl: string;
	MidtierProfileBaseUrl: string;
	MyAccountPcidUrl: string;
	NextAuthUrl: string;
	isSnowplowEnabled: boolean;
	GOOGLE_MAPS_API_KEY: string;
}>;

export const $environment = createStore<EnvironmentContextParams>({}, { sid: '$environment' });

export const useEnvironment = () => {
	return useUnit($environment);
};
