import type { NavCardProps } from '@ldfeplatform/drx-component-library.ui.molecules.nav-card';
import router from 'next/router';
import type { CallToActionFieldsFragment, FeatureCardFieldsFragment, NavCardFieldsFragment } from '../../contentful';
import type { CustomActionsDispatcherParams } from './customActionsDispatcher';

export const customActionHandler = (
	{
		identifier,
		params,
		redirectLink,
	}: {
		identifier?: string | null;
		params?: object;
		redirectLink?: string | null;
	},
	dispatchCustomAction: (a: CustomActionsDispatcherParams) => CustomActionsDispatcherParams
) => {
	if (identifier) {
		dispatchCustomAction({
			identifier,
			payload: params,
		});
	}

	if (redirectLink) {
		dispatchCustomAction({
			identifier: 'close-modal',
			payload: null,
		});
		router.push(redirectLink);
	}
};

export function getSDUINavCardCTAProps(
	item: CallToActionFieldsFragment | null | undefined,
	dispatchCustomAction: (a: CustomActionsDispatcherParams) => CustomActionsDispatcherParams
) {
	return {
		ctaText: item?.label ?? '',
		dataAnalytics: item?.dataAnalytics ?? undefined,
		...(item?.customAction
			? {
					onClickHandler: () =>
						customActionHandler(
							{
								identifier: item?.customAction,
								params: item?.customActionParameters,
								redirectLink: item?.linkUrl,
							},
							dispatchCustomAction
						),
			  }
			: { ctaUrl: item?.linkUrl ?? '' }),
	};
}

export function getSDUINavCardProps(
	item: NavCardFieldsFragment | FeatureCardFieldsFragment,
	dispatchCustomAction: (a: CustomActionsDispatcherParams) => CustomActionsDispatcherParams
): NavCardProps {
	switch (item.__typename) {
		case 'NavCard': {
			const ret = {
				thumbnailSrc: item.cta?.image?.asset?.url ?? '',
				thumbnailAltText: item.cta?.image?.altText ?? '',
				headline: item.headline ?? '',
				bodyText: item.bodyText ?? '',
				...getSDUINavCardCTAProps(item.cta, dispatchCustomAction),
			};

			return ret;
		}
		case 'Card': {
			return {
				thumbnailSrc: item.image?.asset?.url ?? '',
				thumbnailAltText: item.image?.altText ?? '',
				headline: item.heading ?? '',
				bodyText: item.body ?? '',
				...getSDUINavCardCTAProps(item.ctaCollection?.items?.[0], dispatchCustomAction),
			};
		}
		default: {
			return {
				thumbnailSrc: '',
				thumbnailAltText: '',
				headline: '',
				bodyText: '',
				ctaText: '',
				ctaUrl: '',
			};
		}
	}
}
