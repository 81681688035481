import { $localeCode } from '@/entities/routerBridge';
import { BookingIframeManager } from '@/features/bookingIframe';
import { getYear, isAfter, isBefore } from 'date-fns';
import { attach, createEffect, createEvent, createStore, restore, sample } from 'effector';
import { compact, uniq } from 'lodash';
import type {
	AppointmentsAppointmentIdGetLocaleEnum,
	Bundle,
} from '../../services/midtier/booking-management/appointment-history/__generated';
import {
	extractAppointmentDetailsFromBundle,
	extractAppointmentsFromFHIRBundle,
	loadAppointmentByIdHandler,
	loadAppointmentsHandler,
	transformFHIRAppointmentsToPCHAppointments,
	type AppointmentDetails,
	type PCHAppointment,
} from './api';

// Constants and Types
export const ASCENDING_SORT_ORDER = 'ASCENDING_SORT_ORDER';
export const DESCENDING_SORT_ORDER = 'DESCENDING_SORT_ORDER';
export const DEFAULT_APPOINTMENTS_LIST_HEIGHT = '63.8125rem';
export type SortOrder = 'ASCENDING_SORT_ORDER' | 'DESCENDING_SORT_ORDER';
export const pastPeriod = 'past';
export const futurePeriod = 'future';
export type Period = 'past' | 'future';
export type FilterOptions = { statuses: string[]; types: string[]; years: string[]; names: string[] };
export const DEFAULT_FILTER_OPTIONS: FilterOptions = {
	statuses: [],
	types: [],
	years: [],
	names: [],
};
export type ViewPort = 'single-pane' | 'double-pane';

// Stores
export const $appointments = createStore<PCHAppointment[]>([]);
export const $detailedAppointments = createStore<AppointmentDetails[]>([]);
export const $currentAppointmentId = createStore<string | undefined>(undefined, { skipVoid: false });
export const $availableStatuses = $appointments.map((appts) =>
	uniq(compact(appts.map((appt) => (appt.status ? appt.status : undefined))))
);
export const $availableYears = $appointments.map((appts) =>
	uniq(compact(appts.map((appt) => (appt.start ? new Date(appt.start).getFullYear() : undefined))))
);
export const $availableTypes = $appointments.map((appts) =>
	uniq(compact(appts.map((appt) => (appt.type ? appt.type : undefined))))
);
export const $period = createStore<Period>(futurePeriod);

// Events
export const loadAppointments = createEvent();
export const setCurrentAppointmentId = createEvent<string | undefined>();
export const updateApptDetailsById = createEvent();
export const setPeriod = createEvent<Period | undefined>();
export const setSortOrder = createEvent<SortOrder>();
export const updateFilterOptions = createEvent<FilterOptions>();
export const setViewPort = createEvent<ViewPort>();

export const $filterOptions = restore(updateFilterOptions, DEFAULT_FILTER_OPTIONS);
export const $sortOrder = restore(setSortOrder, ASCENDING_SORT_ORDER);
export const $viewPort = restore(setViewPort, 'single-pane');

// State updaters
$period.on(setPeriod, (_, next) => {
	if (!!next) {
		return next;
	}
});
$currentAppointmentId.on(setCurrentAppointmentId, (_, next) => next);

// Effects
export const loadAppointmentsBaseFx = createEffect(loadAppointmentsHandler);
const loadAppointmentDetailsBaseFx = createEffect(loadAppointmentByIdHandler);

export const loadAppointmentsFx = attach({
	source: $localeCode, // Take locale from the store
	mapParams: (_: void, localeCode: AppointmentsAppointmentIdGetLocaleEnum) => ({ localeCode }),
	effect: loadAppointmentsBaseFx, // pass it to the base effect
});

// this derived effect takes appointmentId only
export const loadAppointmentDetailsFx = attach({
	source: $localeCode, // Take locale from the store
	mapParams: (appointmentId: string, localeCode) => ({ appointmentId, localeCode }), // Combine it with appointment Id from derived effect parameter
	effect: loadAppointmentDetailsBaseFx, // pass it to the base effect
});

// Samples
export const $visibleAppointment = sample({
	clock: $currentAppointmentId,
	source: $detailedAppointments,
	fn: (appointments, appointmentId) => appointments.find((appt) => appt.id === appointmentId) || null,
});

sample({
	clock: $currentAppointmentId,
	source: $detailedAppointments,
	filter: function doesNotHaveAppointmentDetails(appointments, appointmentId) {
		if (!appointmentId) {
			return false;
		}
		const haveDetails = appointments.find((a) => a.id === appointmentId);
		return !haveDetails;
	},
	fn: (_details, appointmentId) => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		return appointmentId!;
	},
	target: loadAppointmentDetailsFx,
});

sample({
	clock: loadAppointments,
	target: loadAppointmentsFx,
});

sample({
	clock: loadAppointmentDetailsFx.doneData,
	fn: extractAppointmentDetailsFromBundle,
	target: $detailedAppointments,
});

sample({
	clock: loadAppointmentsFx.doneData,
	fn: (clock: Bundle) => {
		return compact(transformFHIRAppointmentsToPCHAppointments(extractAppointmentsFromFHIRBundle(clock)));
	},
	target: $appointments,
});

export const $appointmentsInCurrentPeriod = sample({
	source: [$appointments, $period, $sortOrder] as const,
	fn: ([appointments, period, sortOrder]) => {
		const now = new Date();
		let result = appointments.filter((appt) => {
			if (!appt.start || !period) return false;
			return period === pastPeriod ? isBefore(appt.start, now) : isAfter(appt.start, now);
		});

		result = sorter(result, sortOrder);

		return result;
	},
});

sample({
	clock: updateApptDetailsById,
	source: $currentAppointmentId,
	filter: (currentAppointmentId) => currentAppointmentId !== undefined,
	target: loadAppointmentDetailsFx,
});

export const $appointmentsView = sample({
	source: [$appointments, $period, $sortOrder, $filterOptions] as const,
	fn: ([appointments, period, sortOrder, filterOptions]) => {
		const now = new Date();
		let result = appointments.filter((appt) => {
			if (!appt.start || !period) return false;
			return period === pastPeriod ? isBefore(appt.start, now) : isAfter(appt.start, now);
		});
		const { types, years, statuses, names } = filterOptions;
		if (types.length > 0) result = result.filter((appt) => appt.type && types.includes(appt.type));
		if (years.length > 0)
			result = result.filter((appt) => appt.start && years.includes(getYear(appt.start).toString()));
		if (statuses.length > 0) result = result.filter((appt) => appt.status && statuses.includes(appt.status));
		if (names.length > 0)
			result = result.filter(
				(appt) =>
					appt.patientFirstName &&
					appt.patientLastName &&
					names.includes(`${appt.patientFirstName} ${appt.patientLastName}`)
			);
		result = sorter(result, sortOrder);

		return result;
	},
});

export const $hasNoAppointmentsView = sample({
	clock: $appointmentsView,
	fn: (appointmentsView) => !appointmentsView || appointmentsView?.length === 0,
});

sample({
	clock: [loadAppointmentsFx.done, setPeriod],
	source: [$appointmentsView, $viewPort, BookingIframeManager.$bookingFlowType] as const,
	filter: ([_, viewPort, bookingFlowType]) => bookingFlowType == 'create' && viewPort === 'double-pane',
	fn: ([appointments]) => {
		// When on desktop and user selects past / present appointments automatically select the first appt
		return appointments[0]?.id;
	},
	target: setCurrentAppointmentId,
});

export const $appointmentDetailsLoading = loadAppointmentDetailsFx.pending;

export const $appointmentsLoading = loadAppointmentsFx.pending;

// Helpers
export function sorter(appointments: PCHAppointment[], sortOrder: SortOrder) {
	const sortFactor = sortOrder === ASCENDING_SORT_ORDER ? 1 : -1;
	return appointments.sort((a, b) => {
		const dateA = a.start ? new Date(a.start).getTime() : 0;
		const dateB = b.start ? new Date(b.start).getTime() : 0;
		return sortFactor * (dateA - dateB);
	});
}

export function periodTypeGuard(period: string) {
	switch (period) {
		case pastPeriod:
		case futurePeriod:
			return period;
		default:
			return undefined;
	}
}
