import { parseVariant } from '@/shared/contentfulTypeConverters';
import { Image, Text } from '@chakra-ui/react';
import { Modal } from '@ldfeplatform/drx-component-library.ui.molecules.modal';
import { useUnit } from 'effector-react';
import { useEffect, type FunctionComponent } from 'react';
import { updateGTMDataLayer } from '../../../src/hooks/analytic';
import { replaceStringTemplates } from '../../../src/template';
import { customActionHandler, dispatchCustomAction } from '../customActions';
import { $currentModal, $isVisible, onModalHidden, showModal } from './model';

export const ModalOutlet: FunctionComponent = () => {
	const [isVisible, currentModal, showModalEvent, onModalHiddenEvent, dispatchCustomActionEvent] = useUnit([
		$isVisible,
		$currentModal,
		showModal,
		onModalHidden,
		dispatchCustomAction,
	]);

	const modalContent = currentModal?.data?.modalCollection?.items[0]?.featureCard;

	useEffect(() => {
		window.showModal = showModalEvent;
	}, [showModalEvent]);

	if (!isVisible) {
		return null;
	}

	if (!modalContent) {
		return null;
	}

	const { heading, body, image, ctaCollection } = modalContent;

	if (!heading) {
		return null;
	}

	const modalCTAs = ctaCollection?.items?.map((cta) => {
		const onClick = () => {
			if (cta?.dataAnalytics) updateGTMDataLayer({ event: 'customLink', linkName: cta?.dataAnalytics });
			customActionHandler(
				{
					identifier: cta?.customAction,
					params: cta?.customActionParameters,
					redirectLink: cta?.linkUrl,
				},
				dispatchCustomActionEvent
			);
		};

		return {
			label: cta?.label ?? '',
			variant: parseVariant(cta?.variant),
			onClick,
		};
	});

	if (!isVisible || !currentModal) return null;
	const templateProps = currentModal.templateProps;
	const templateVariables = currentModal.templateVariables;
	const isMarkdownModal = currentModal.templateProps.isMarkdownModal;
	const storeData = templateVariables.storeData;
	const modelText = storeData && Object.keys(storeData).length === 0 ? body : replaceStringTemplates(body, storeData);

	return (
		<>
			<pre>{JSON.stringify({}, null, 2)}</pre>
			<Modal
				isOpen={isVisible}
				title={replaceStringTemplates(heading, templateVariables)}
				onClose={onModalHiddenEvent}
				autoFocus={false}
				footerCTAs={modalCTAs}
				isCentered
				ctaOrientation={templateProps?.ctaOrientation}
				{...(isMarkdownModal ? { markdown: modelText } : null)}
			>
				{!isMarkdownModal && (
					<Text textAlign="center" whiteSpace="pre-line">
						{modelText}
					</Text>
				)}
				{/* Ideally the width and height would come from contentful to help with CLS performance metrics */}
				{image && (
					<Image mx="auto" htmlWidth={338} htmlHeight={217} src={image?.asset?.url} alt={image?.altText ?? ''} />
				)}
			</Modal>
		</>
	);
};
