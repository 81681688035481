import { hideModal, modalLoadingFailed, onModalHidden, showModal } from '@/features/modal/model';
import { createFactory, invoke } from '@withease/factories';
import { createEvent, createStore, sample } from 'effector';
import { debug } from 'patronum';
import type { PartnerConsents } from '../../services/midtier/user/__generated';
import { PartnerConsentsContract, type Consent, type PartnerName } from './contracts';

export type ConsentManagerFactoryParams = {
	showModal: typeof showModal;
	hideModal: typeof hideModal;
	onModalHidden: typeof onModalHidden;
	modalLoadingFailed: typeof modalLoadingFailed;
};

const getModalIdentifier = (partnerName: PartnerName) => `request-consent-${partnerName}-modal`;

const updateOrCreate = (arr: Consent[], newVal: Consent): Consent[] => {
	const index = arr.findIndex(({ partnerName }) => partnerName === newVal.partnerName);
	if (index > -1) {
		arr[index] = newVal;
	} else {
		arr.push(newVal);
	}
	return [...arr];
};

const ConsentManagerFactory = createFactory(
	({ showModal, hideModal, onModalHidden, modalLoadingFailed }: ConsentManagerFactoryParams) => {
		/** When external source updates consents, update internal store */
		const updateConsent = createEvent<PartnerConsents>();

		// * This event is called when the partner name does not exist or the consent is false
		const requestConsentCheck = createEvent<PartnerName>();

		// * This event will be called when customer accepts consent in the Modal
		const consentAccepted = createEvent<Consent>();

		const $consents = createStore<Consent[]>([]);
		const $pendingConsent = createStore<PartnerName | null>(null);

		// * When external source updates consents, update internal store
		$consents.on(updateConsent, (_, c) => PartnerConsentsContract.parse(c));

		// * This event is called when a consent check comes back from local cache
		const consentCheckCompletedCached = sample({
			clock: requestConsentCheck,
			source: $consents,
			filter(consents, partnerName) {
				const consent = consents.find((x) => x.partnerName === partnerName);
				return consent?.tos || false;
			},
			fn(consents, partnerName) {
				const found = consents.find((x) => x.partnerName === partnerName)!;
				return found;
			},
		});

		// * If the consent for the partner name does not exist or the consent is false - trigger the modal
		sample({
			clock: requestConsentCheck,
			source: $consents,
			filter(consents, partnerName) {
				const consent = consents.find((x) => x.partnerName === partnerName);

				return !consent?.tos;
			},
			fn(_consents, partnerName) {
				return {
					identifier: getModalIdentifier(partnerName),
					templateProps: {},
					templateVariables: {},
				};
			},
			target: showModal,
		});

		// * When customer pressed I agree button, update local consent
		$consents.on(consentAccepted, (currentValue, newConsent) => updateOrCreate(currentValue, newConsent));

		// * Set our internal state as pending
		sample({
			clock: requestConsentCheck,
			target: $pendingConsent,
		});

		// * This event happens when we have received a consent response that matches currently pending consent request
		const consentCheckCompletedInteractively = sample({
			clock: consentAccepted,
			source: $pendingConsent,
			filter(pendingConsent, updatedConsent) {
				return updatedConsent.partnerName === pendingConsent;
			},
			fn(_, updatedConsent) {
				return updatedConsent;
			},
		});

		// * When either check completes from cache or interactively
		const consentCheckCompleted = sample({
			clock: [consentCheckCompletedCached, consentCheckCompletedInteractively],
		});

		// * Reset the pending state if the request has completed for the currently requested consent
		sample({
			clock: consentCheckCompleted,
			source: $pendingConsent,
			filter(pendingConsent, consent) {
				return consent.partnerName === pendingConsent;
			},
			fn: () => null,
			target: [$pendingConsent, hideModal],
		});

		// * Reset the pending state if we failed to load the modal
		sample({
			clock: modalLoadingFailed,
			source: $pendingConsent,
			filter(partnerName, identifier) {
				return getModalIdentifier(partnerName!) === identifier;
			},
			fn: () => null,
			target: $pendingConsent,
		});

		// * Reset the pending state if modal was closed
		sample({
			clock: onModalHidden,
			source: $pendingConsent,
			filter: (partnerName) => !!partnerName,
			fn: () => null,
			target: $pendingConsent,
		});

		const $isPending = $pendingConsent.map((x) => !!x);

		return {
			consentAccepted,
			consentCheckCompleted,
			requestConsentCheck,
			updateConsent,
			$consents,
			$isPending,
		};
	}
);

export const ConsentManager = invoke(ConsentManagerFactory, {
	showModal,
	hideModal,
	onModalHidden,
	modalLoadingFailed,
});

debug(ConsentManager);
