import { RouterBridge } from '@/entities/routerBridge';
import { ApplicationLoader } from '@/features/application';
import { BookingIframe } from '@/features/bookingIframe';
import { ModalOutlet } from '@/features/modal';
import { ToastOutlet } from '@/features/toast';
import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import { EffectorNext } from '@effector/next';
import { pchTheme, sdmTheme } from '@ldfeplatform/drx-component-library.themes.theme';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { client } from '../src/contentful';
import { type BasicPageProps } from '../src/contentful/ssr';
import '../styles/globals.css';
import '../wdyr';

function App({ Component, pageProps: { banner, scope, ...pageProps } }: AppProps<BasicPageProps>) {
	const theme = banner === 'SDM' ? sdmTheme : pchTheme;
	return (
		<>
			<ChakraProvider theme={theme}>
				<EffectorNext values={scope}>
					<ApplicationLoader />
					<ModalOutlet />
					<BookingIframe />
					<ToastOutlet />
					<RouterBridge />
					<ApolloProvider client={client}>
						<Component {...pageProps} />
					</ApolloProvider>
				</EffectorNext>
			</ChakraProvider>
		</>
	);
}

export default appWithTranslation(App);
