export type SessionContextParams =
	| {
			status: 'authenticated';
			access_token: string;
			id_token: string;
			pcid: string;
	  }
	| { status: 'unauthenticated' };

export type SessionStatus = SessionContextParams['status'];

export function isAuthenticatedSession(session: SessionContextParams) {
	return session.status === 'authenticated';
}
