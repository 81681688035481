import { errorToToast, showToast } from '@/features/toast';
import { isAuthenticatedSession, type SessionContextParams } from '@/shared/session';
import { testingInternals } from '@/shared/testingInternals';
import { createFactory, invoke } from '@withease/factories';
import { createEffect, createEvent, merge, restore, sample, split, type Store } from 'effector';
import { debug } from 'patronum';
import { $session } from '../application';
import {
	cancelAppointment,
	createAppointment as createAppointmentApi,
	rescheduleAppointment,
	type BookingFlowType,
	type CreateAppointmentFxInput,
	type ModifyAppointmentFxInput,
} from './api';
import type {
	AbandonMessage,
	CloseMessage,
	EnterMessage,
	ErrorMessage,
	IFrameEvent,
	SuccessMessage,
} from './contracts';

export type BookingIframeManagerSettings = {
	session: Store<SessionContextParams>;
	showToast: typeof showToast;
};

export type BookingEventType = 'walkin' | 'booking';

export type FlowSucceededPayload = {
	appointmentId: string;
	province: string;
	type: string;
	event: 'success';
	eventType: BookingEventType;
};

export const BookingIframeManagerFactory = createFactory(({ session, showToast }: BookingIframeManagerSettings) => {
	/* Events */
	const createAppointment = createEvent<CreateAppointmentFxInput>();
	const modifyAppointment = createEvent<ModifyAppointmentFxInput>();
	const bookingFlowTypeChanged = createEvent<BookingFlowType>();

	const hideIframe = createEvent();
	const flowEntered = createEvent<EnterMessage>();
	const flowAbandoned = createEvent<AbandonMessage>();
	const flowErrored = createEvent<ErrorMessage>();
	const flowSucceeded = createEvent<SuccessMessage>();
	const flowClosed = createEvent<CloseMessage>();

	const handleIframeEvent = createEvent<IFrameEvent>();

	/* Effects */
	const createAppointmentFx = createEffect(createAppointmentApi);
	const modifyAppointmentFx = createEffect((params: ModifyAppointmentFxInput) => {
		return params.purpose === 'reschedule' ? rescheduleAppointment(params) : cancelAppointment(params);
	});

	/* Stores */

	// Create store based on effect result, automatically updates when effect completes
	const $iframeUrl = restore(merge([createAppointmentFx.doneData, modifyAppointmentFx.doneData]), null);

	const $bookingFlowType = restore(bookingFlowTypeChanged, 'create');

	/* Business Logic */

	sample({
		clock: createAppointmentFx.failData, // When MidTier call fails
		fn: errorToToast, // Convert Error to Toast
		target: showToast, // Forward to showToast
	});

	sample({
		clock: createAppointmentFx.fail,
		fn: (e) => ({ event: 'error' as const, details: e.error.message }),
		target: flowErrored,
	});

	sample({
		clock: createAppointment, // When createAppointment gets trigered
		source: session, // Grab session data
		filter: isAuthenticatedSession, // Check if we are authenticated
		fn: (_session, parameters) => parameters, // Pass parameters from the event
		target: createAppointmentFx, // Invoke effect with these parameters
	});

	/* Business Logic for modify appointment */
	sample({
		clock: modifyAppointmentFx.failData, // When MidTier call fails
		fn: errorToToast, // Convert Error to Toast
		target: showToast, // Forward to showToast
	});

	sample({
		clock: modifyAppointmentFx.fail,
		fn: (e) => ({ event: 'error' as const, details: e.error.message }),
		target: flowErrored,
	});

	sample({
		clock: modifyAppointment, // When modifyAppointment gets triggered
		source: session, // Grab session data
		filter: isAuthenticatedSession, // Check if we are authenticated
		fn: (_session, parameters) => parameters, // Pass parameters from the event
		target: modifyAppointmentFx, // Invoke effect with these parameters
	});

	sample({
		clock: modifyAppointment,
		fn: ({ purpose }) => purpose,
		target: bookingFlowTypeChanged,
	});

	sample({
		clock: createAppointment,
		fn: () => 'create' as const,
		target: bookingFlowTypeChanged,
	});

	/* IFrame Events */

	split({
		// Only route events if the iframe is active
		source: sample({
			clock: handleIframeEvent,
			source: $iframeUrl,
			filter: (iframeUrl) => !!iframeUrl,
			fn: (_, e) => e,
		}),
		match: (event) => event.event,
		cases: {
			abandon: flowAbandoned,
			close: flowClosed,
			enter: flowEntered,
			error: flowErrored,
			success: flowSucceeded,
		},
	});

	// Hide iframe if any of those happen
	$iframeUrl.reset([hideIframe, flowAbandoned, flowClosed, flowErrored]);

	/* Public API */
	return {
		/** This store holds the url of the iframe */
		$iframeUrl,
		/** This event is triggered if the customer abandons the flow by pressing "Cancel" */
		flowAbandoned,
		/** This event is triggered when the flow had completed */
		flowClosed,
		/** This event is triggered when the iframe is first displayed */
		flowEntered,
		/** This event is triggered if the iframe has encountered an error */
		flowErrored,
		/** This event is triggered when an appointment is booked successfully */
		flowSucceeded,
		/** Trigger this event to begin the iframe flow */
		createAppointment,
		modifyAppointment,
		/** Trigger this event to handle postMessage events from the iframe */
		handleIframeEvent,
		/** Trigger this event to hide the iframe */
		hideIframe,
		$bookingFlowType,
		/** Can be create, reschedule or cancel */
		[testingInternals]: {
			createAppointmentFx,
			modifyAppointmentFx,
		},
	};
});

export const BookingIframeManager = invoke(BookingIframeManagerFactory, {
	session: $session,
	showToast,
});

export type BookingIframeModel = typeof BookingIframeManager;

debug({ ...BookingIframeManager, ...BookingIframeManager[testingInternals] });
